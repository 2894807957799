import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from "../components/CustomLinks"
import FloatingSearch from "../components/floatingSearch"

import logo_color from "../../static/images/logo_color.png"

export default function Terms(props) {
  return (
    <Layout>
      <Helmet>
        <title>Terms - EOG Resources, Inc.</title>
      </Helmet>
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch />
      </div>
      <div className="max-width-container">
        <Link to="/">
          <img
            src={logo_color}
            alt="logo"
            style={{ padding: "10px", marginLeft: "20px", marginTop: "30px" }}
          />
        </Link>
      </div>
      <div className="max-width-container page terms-page last-page">
        <h2 className="page-subheader">Terms of Use</h2>
        <p>
          Copyright © 2022 EOG Resources, Inc. All rights reserved. Information
          on this website is provided “as is” without warranty of any kind,
          either express or implied, including but not limited to the implied
          warranties of merchantability, fitness for a particular purpose and
          the timeliness of the information. You assume all risk in using the
          information. In no event shall EOG Resources, Inc. (EOG) or its
          representatives be liable for any special, indirect or consequential
          damages resulting from the use of the information.
        </p>
        <p>
          <b>Cautionary Notice Regarding Forward-Looking Statements</b>
        </p>
        <p>
          This website may include forward-looking statements within the meaning
          of Section 27A of the Securities Act of 1933, as amended, and
          Section 21E of the Securities Exchange Act of 1934, as amended. All
          statements, other than statements of historical facts, including,
          among others, statements and projections regarding EOG's future
          financial position, operations, performance, business strategy, goals,
          returns and rates of return, budgets, reserves, levels of production,
          capital expenditures, costs and asset sales, statements regarding
          future commodity prices and statements regarding the plans and
          objectives of EOG's management for future operations, are
          forward-looking statements. EOG typically uses words such as "expect,"
          "anticipate," "estimate," "project," "strategy," "intend," "plan,"
          "target," "aims," "ambition," "initiative," "goal," "may," "will,"
          "focused on," "should" and "believe" or the negative of those terms or
          other variations or comparable terminology to identify its
          forward-looking statements. In particular, statements, express or
          implied, concerning EOG's future operating results and returns or
          EOG's ability to replace or increase reserves, increase production,
          generate returns and rates of return, replace or increase drilling
          locations, reduce or otherwise control operating costs and capital
          expenditures, generate cash flows, pay down or refinance indebtedness,
          achieve, reach or otherwise meet initiatives, plans, goals, ambitions
          or targets with respect to emissions, other environmental matters,
          safety matters or other ESG (environmental/social/governance) matters,
          or pay and/or increase dividends are forward-looking statements.
          Forward-looking statements are not guarantees of performance. Although
          EOG believes the expectations reflected in its forward-looking
          statements are reasonable and are based on reasonable assumptions, no
          assurance can be given that these assumptions are accurate or that any
          of these expectations will be achieved (in full or at all) or will
          prove to have been correct. Moreover, EOG's forward-looking statements
          may be affected by known, unknown or currently unforeseen risks,
          events or circumstances that may be outside EOG's control.
          Furthermore, this website may include or reference certain
          forward-looking, non-GAAP financial measures, such as free cash flow
          and cash flow from operations before changes in
          working capital, and certain related estimates regarding future
          performance, results and financial position. Because we provide these
          measures on a forward-looking basis, we cannot reliably or reasonably
          predict certain of the necessary components of the most directly
          comparable forward-looking GAAP measures, such as future changes in
          working capital. Accordingly, we are unable to present a quantitative
          reconciliation of such forward-looking, non-GAAP financial measures to
          the respective most directly comparable forward-looking GAAP financial
          measures. Management believes these forward-looking, non-GAAP measures
          may be a useful tool for the investment community in comparing EOG's
          forecasted financial performance to the forecasted financial
          performance of other companies in the industry. Any such
          forward-looking measures and estimates are intended to be illustrative
          only and are not intended to reflect the results that EOG will
          necessarily achieve for the period(s) presented; EOG's actual results
          may differ materially from such measures and estimates. Important
          factors that could cause EOG's actual results to differ materially
          from the expectations reflected in EOG's forward-looking statements
          include, among others:
        </p>

        <ul
          style={{
            listStyle: "disc",
            padding: "1em",
            margin: "1em",
          }}
        >
          <li>
            the timing, extent and duration of changes in prices for, supplies
            of, and demand for, crude oil and condensate, natural gas liquids
            (NGLs), natural gas and related commodities;
          </li>
          <li>
            the extent to which EOG is successful in its efforts to acquire or
            discover additional reserves;
          </li>
          <li>
            the extent to which EOG is successful in its efforts to (i)
            economically develop its acreage in, (ii) produce reserves and
            achieve anticipated production levels and rates of return from,
            (iii) decrease or otherwise control its drilling, completion,
            operating and capital costs related to, and (iv) maximize reserve
            recovery from, its existing and future crude oil and natural gas
            exploration and development projects and associated potential and
            existing drilling locations;
          </li>
          <li>
            the extent to which EOG is successful in its efforts to market its
            production of crude oil and condensate, NGLs and natural gas;
          </li>
          <li>
            security threats, including cybersecurity threats and disruptions to
            our business and operations from breaches of our information
            technology systems, physical breaches of our facilities and other
            infrastructure or breaches of the information technology systems,
            facilities and infrastructure of third parties with which we
            transact business;
          </li>
          <li>
            the availability, proximity and capacity of, and costs associated
            with, appropriate gathering, processing, compression, storage,
            transportation, refining, and export facilities;
          </li>
          <li>
            the availability, cost, terms and timing of issuance or execution of
            mineral licenses and leases and governmental and other permits and
            rights-of-way, and EOG's ability to retain mineral licenses and
            leases;
          </li>
          <li>
            the impact of, and changes in, government policies, laws and
            regulations, including climate change-related regulations, policies
            and initiatives (for example, with respect to air emissions); tax
            laws and regulations (including, but not limited to, carbon tax
            legislation); environmental, health and safety laws and regulations
            relating to disposal of produced water, drilling fluids and other
            wastes, hydraulic fracturing and access to and use of water; laws
            and regulations affecting the leasing of acreage and permitting for
            oil and gas drilling and the calculation of royalty payments in
            respect of oil and gas production; laws and regulations imposing
            additional permitting and disclosure requirements, additional
            operating restrictions and conditions or restrictions on drilling
            and completion operations and on the transportation of crude oil and
            natural gas; laws and regulations with respect to derivatives and
            hedging activities; and laws and regulations with respect to the
            import and export of crude oil, natural gas and related commodities;
          </li>
          <li>
            the impact of climate change-related policies and initiatives at the
            corporate and/or investor community levels and other potential
            developments related to climate change, such as (but not limited to)
            changes in consumer and industrial/commercial behavior, preferences
            and attitudes with respect to the generation and consumption of
            energy; increased availability of, and increased consumer and
            industrial/commercial demand for, competing energy sources
            (including alternative energy sources); technological advances with
            respect to the generation, transmission, storage and consumption of
            energy; alternative fuel requirements; energy conservation measures;
            decreased demand for, and availability of, services and facilities
            related to the exploration for, and production of, crude oil, NGLs
            and natural gas; and negative perceptions of the oil and gas
            industry and, in turn, reputational risks associated with the
            exploration for, and production of, crude oil, NGLs and natural gas;
          </li>
          <li>
            EOG's ability to effectively integrate acquired crude oil and
            natural gas properties into its operations, fully identify existing
            and potential problems with respect to such properties and
            accurately estimate reserves, production and drilling, completing
            and operating costs with respect to such properties;
          </li>
          <li>
            the extent to which EOG's third-party-operated crude oil and natural
            gas properties are operated successfully, economically and in
            compliance with applicable laws and regulations;
          </li>
          <li>
            competition in the oil and gas exploration and production industry
            for the acquisition of licenses, leases and properties;
          </li>
          <li>
            the availability and cost of, and competition in the oil and gas
            exploration and production industry for, employees and other
            personnel, facilities, equipment, materials (such as water, sand and
            tubulars) and services;
          </li>
          <li>
            the accuracy of reserve estimates, which by their nature involve the
            exercise of professional judgment and may therefore be imprecise;
          </li>
          <li>
            weather, including its impact on crude oil and natural gas demand,
            and weather-related delays in drilling and in the installation and
            operation (by EOG or third parties) of production, gathering,
            processing, refining, compression, storage, transportation, and
            export facilities;
          </li>
          <li>
            the ability of EOG's customers and other contractual counterparties
            to satisfy their obligations to EOG and, related thereto, to access
            the credit and capital markets to obtain financing needed to satisfy
            their obligations to EOG;{" "}
          </li>
          <li>
            EOG's ability to access the commercial paper market and other credit
            and capital markets to obtain financing on terms it deems
            acceptable, if at all, and to otherwise satisfy its capital
            expenditure requirements;
          </li>
          <li>
            the extent to which EOG is successful in its completion of planned
            asset dispositions;
          </li>
          <li>
            the extent and effect of any hedging activities engaged in by EOG;
          </li>
          <li>
            the timing and extent of changes in foreign currency exchange rates,
            interest rates, inflation rates, global and domestic financial
            market conditions and global and domestic general economic
            conditions;
          </li>
          <li>
            the duration and economic and financial impact of epidemics,
            pandemics or other public health issues, including the COVID-19
            pandemic;
          </li>
          <li>
            geopolitical factors and political conditions and developments
            around the world (such as the imposition of tariffs or trade or
            other economic sanctions, political instability and armed conflict),
            including in the areas in which EOG operates;
          </li>
          <li>
            the extent to which EOG incurs uninsured losses and liabilities or
            losses and liabilities in excess of its insurance coverage;
          </li>
          <li>acts of war and terrorism and responses to these acts; and </li>
          <li>
            the other factors described under ITEM 1A, Risk Factors of EOG's
            Annual Report on Form 10-K for the fiscal year ended December 31,
            2021 and any updates to those factors set forth in EOG's subsequent
            Quarterly Reports on Form 10-Q or Current Reports on Form 8-K.
          </li>
        </ul>
        <p>
          In light of these risks, uncertainties and assumptions, the events
          anticipated by EOG's forward-looking statements may not occur, and, if
          any of such events do, we may not have anticipated the timing of their
          occurrence or the duration or extent of their impact on our actual
          results. Accordingly, you should not place any undue reliance on any
          of EOG's forward-looking statements. EOG's forward-looking statements
          speak only as of the date made, and EOG undertakes no obligation,
          other than as required by applicable law, to update or revise its
          forward-looking statements, whether as a result of new information,
          subsequent events, anticipated or unanticipated circumstances or
          otherwise.
        </p>
        <p>
          <b>Oil and Gas Reserves; Non-GAAP Financial Measures</b>
        </p>
        <p>
          The United States Securities and Exchange Commission (SEC) permits oil
          and gas companies, in their filings with the SEC, to disclose not only
          “proved” reserves (i.e., quantities of oil and gas that are estimated
          to be recoverable with a high degree of confidence), but also
          “probable” reserves (i.e., quantities of oil and gas that are as
          likely as not to be recovered) as well as “possible” reserves (i.e.,
          additional quantities of oil and gas that might be recovered, but with
          a lower probability than probable reserves). Statements of reserves
          are only estimates and may not correspond to the ultimate quantities
          of oil and gas recovered. Any reserve or resource estimates provided
          on this website that are not specifically designated as being
          estimates of proved reserves may include “potential” reserves,
          “resource potential” and/or other estimated reserves or estimated
          resources not necessarily calculated in accordance with, or
          contemplated by, the SEC's latest reserve reporting guidelines.
          Investors are urged to consider closely the disclosure in EOG's Annual
          Report on Form 10-K for the fiscal year ended December 31, 2021,
          available from EOG at P.O. Box 4362, Houston, Texas 77210-4362 (Attn:
          Investor Relations). You can also obtain this report from the SEC by
          calling 1-800-SEC-0330 or from the SEC's website at www.sec.gov. In
          addition, reconciliation schedules and definitions for non-GAAP
          financial measures can be found on this website.
        </p>
      </div>
    </Layout>
  )
}
